<template>
  <div @click="closeAllPopovers()">
    <b-table-simple class="mt-3" bordered small foot-clone responsive>
      <b-thead>
        <b-tr>
          <b-th rowspan="2" class="th-upper text-center align-middle"
            >Nivel Cognitivo</b-th
          >
          <b-th rowspan="2" class="th-upper text-center align-middle"
            >Cantidad de
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                true,
                "Criterios de Evaluación"
              )
            }}</b-th
          >
          <b-th colspan="8" class="th-upper text-center"
            >Subtipo de Preguntas</b-th
          >
        </b-tr>
        <b-tr>
          <b-th rowspan="2" class="text-center th-color-variant"
            >Desarrollo Breve</b-th
          >
          <b-th rowspan="2" class="text-center th-color-variant align-middle"
            >Puntaje</b-th
          >
          <b-th rowspan="2" class="text-center th-color-variant"
            >Desarrollo Extenso</b-th
          >
          <b-th rowspan="2" class="text-center th-color-variant align-middle"
            >Puntaje</b-th
          >
          <b-th rowspan="2" class="text-center th-color-variant">
            Selección Simple
          </b-th>
          <b-th rowspan="2" class="text-center th-color-variant align-middle">
            Puntaje
          </b-th>
          <b-th rowspan="2" class="text-center th-color-variant">
            Selección Múltiple
          </b-th>
          <b-th rowspan="2" class="text-center th-color-variant align-middle">
            Puntaje
          </b-th>
        </b-tr>
      </b-thead>
      <template v-if="questions && questions.length > 0">
        <b-tbody>
          <template>
            <b-tr
              v-for="cognitive_level in cognitive_levels"
              :key="cognitive_level.id"
            >
              <b-td
                ><div
                  class="cognitive-level text-center align-middle"
                  v-b-tooltip.v-secondary.noninteractive="
                    cognitive_level.level + '. ' + cognitive_level.name
                  "
                  :class="{ 'cl-questions-about': !allows_crud }"
                >
                  {{ cognitive_level.level }}
                </div>
              </b-td>
              <b-td class="text-center align-middle">{{
                getECPerCognitiveLevel(cognitive_level.id)
                  ? getECPerCognitiveLevel(cognitive_level.id)
                  : "-"
              }}</b-td>
              <b-td class="text-center align-middle">
                <div
                  :id="`cl-questions-cell-1-${cognitive_level.id}`"
                  :class="{
                    'cl-questions-cell':
                      allows_crud &&
                      cognitiveLevelQuestions(cognitive_level.id, 1, 'Breve')
                        .length,
                    'cl-questions-about': cognitiveLevelQuestions(
                      cognitive_level.id,
                      1,
                      'Breve'
                    ).length,
                  }"
                >
                  {{
                    cognitiveLevelQuestions(cognitive_level.id, 1, "Breve") &&
                    cognitiveLevelQuestions(cognitive_level.id, 1, "Breve")
                      .length > 0
                      ? cognitiveLevelQuestions(cognitive_level.id, 1, "Breve")
                          .length
                      : "-"
                  }}
                  <b-popover
                    v-if="
                      cognitiveLevelQuestions(cognitive_level.id, 1, 'Breve')
                        .length
                    "
                    :target="`cl-questions-cell-1-${cognitive_level.id}`"
                    triggers="click"
                  >
                    <template #title>Preguntas</template>
                    <template
                      v-for="question in cognitiveLevelQuestions(
                        cognitive_level.id,
                        1,
                        'Breve'
                      )"
                    >
                      <div :key="question.id" v-if="!isNaN(question.id)">
                        <div
                          v-if="allows_crud"
                          :id="`question-link-${question.id}`"
                          class="popover-question"
                          @click="
                            openQuestionForm(question.id, cognitive_level.id)
                          "
                        >
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <div v-else>
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <b-tooltip
                          v-if="allows_crud"
                          :target="`question-link-${question.id}`"
                          variant="secondary"
                          placement="right"
                          delay="100"
                        >
                          <b-icon
                            class="mr-2"
                            icon="box-arrow-up-right"
                          ></b-icon
                          >Abrir formulario de pregunta
                        </b-tooltip>

                        <b-modal
                          :id="`edit-cl-question-${question.id}`"
                          title="Editar Pregunta"
                          size="xl"
                          hide-footer
                          no-close-on-backdrop
                        >
                          <QuestionForm
                            :Question="question"
                            :question_test_id="question.question_test"
                            :evaluation_id="evaluation_id"
                            @updated="slotUpdatedQuestion(question)"
                          ></QuestionForm>
                        </b-modal>
                      </div>
                      <div v-else :key="question.id">N/A</div>
                    </template>
                  </b-popover>
                </div>
              </b-td>

              <b-td class="text-center align-middle">{{
                clQuestionScore(cognitive_level.id, 1, "Breve")
                  ? clQuestionScore(cognitive_level.id, 1, "Breve")
                  : "-"
              }}</b-td>
              <b-td class="text-center align-middle">
                <div
                  :id="`cl-questions-cell-2-${cognitive_level.id}`"
                  :class="{
                    'cl-questions-cell':
                      allows_crud &&
                      cognitiveLevelQuestions(cognitive_level.id, 1, 'Extenso')
                        .length,
                    'cl-questions-about': cognitiveLevelQuestions(
                      cognitive_level.id,
                      1,
                      'Extenso'
                    ).length,
                  }"
                >
                  {{
                    cognitiveLevelQuestions(cognitive_level.id, 1, "Extenso") &&
                    cognitiveLevelQuestions(cognitive_level.id, 1, "Extenso")
                      .length > 0
                      ? cognitiveLevelQuestions(
                          cognitive_level.id,
                          1,
                          "Extenso"
                        ).length
                      : "-"
                  }}
                  <b-popover
                    v-if="
                      cognitiveLevelQuestions(cognitive_level.id, 1, 'Extenso')
                        .length
                    "
                    :target="`cl-questions-cell-2-${cognitive_level.id}`"
                    triggers="click"
                  >
                    <template #title>Preguntas</template>
                    <template
                      v-for="question in cognitiveLevelQuestions(
                        cognitive_level.id,
                        1,
                        'Extenso'
                      )"
                    >
                      <div :key="question.id" v-if="!isNaN(question.id)">
                        <div
                          v-if="allows_crud"
                          :id="`question-link-${question.id}`"
                          class="popover-question"
                          @click="
                            openQuestionForm(question.id, cognitive_level.id)
                          "
                        >
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <div v-else>
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <b-tooltip
                          v-if="allows_crud"
                          :target="`question-link-${question.id}`"
                          variant="secondary"
                          placement="right"
                          delay="100"
                        >
                          <b-icon
                            class="mr-2"
                            icon="box-arrow-up-right"
                          ></b-icon
                          >Abrir formulario de pregunta
                        </b-tooltip>

                        <b-modal
                          :id="`edit-cl-question-${question.id}`"
                          title="Editar Pregunta"
                          size="xl"
                          hide-footer
                          no-close-on-backdrop
                        >
                          <QuestionForm
                            :Question="question"
                            :question_test_id="question.question_test"
                            :evaluation_id="evaluation_id"
                            @updated="slotUpdatedQuestion(question)"
                          ></QuestionForm>
                        </b-modal>
                      </div>
                      <div v-else :key="question.id">N/A</div>
                    </template>
                  </b-popover>
                </div>
              </b-td>
              <b-td class="text-center align-middle">{{
                clQuestionScore(cognitive_level.id, 1, "Extenso")
                  ? clQuestionScore(cognitive_level.id, 1, "Extenso")
                  : "-"
              }}</b-td>
              <b-td class="text-center align-middle">
                <div
                  :id="`cl-questions-cell-3-${cognitive_level.id}`"
                  :class="{
                    'cl-questions-cell':
                      allows_crud &&
                      cognitiveLevelQuestions(cognitive_level.id, 2, 'Simple')
                        .length,
                    'cl-questions-about': cognitiveLevelQuestions(
                      cognitive_level.id,
                      2,
                      'Simple'
                    ).length,
                  }"
                >
                  {{
                    cognitiveLevelQuestions(cognitive_level.id, 2, "Simple") &&
                    cognitiveLevelQuestions(cognitive_level.id, 2, "Simple")
                      .length > 0
                      ? cognitiveLevelQuestions(cognitive_level.id, 2, "Simple")
                          .length
                      : "-"
                  }}
                  <b-popover
                    v-if="
                      cognitiveLevelQuestions(cognitive_level.id, 2, 'Simple')
                        .length
                    "
                    :target="`cl-questions-cell-3-${cognitive_level.id}`"
                    triggers="click"
                  >
                    <template #title>Preguntas</template>
                    <template
                      v-for="question in cognitiveLevelQuestions(
                        cognitive_level.id,
                        2,
                        'Simple'
                      )"
                    >
                      <div :key="question.id" v-if="!isNaN(question.id)">
                        <div
                          v-if="allows_crud"
                          :id="`question-link-${question.id}`"
                          class="popover-question"
                          @click="
                            openQuestionForm(question.id, cognitive_level.id)
                          "
                        >
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <div v-else>
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <b-tooltip
                          v-if="allows_crud"
                          :target="`question-link-${question.id}`"
                          variant="secondary"
                          placement="right"
                          delay="100"
                        >
                          <b-icon
                            class="mr-2"
                            icon="box-arrow-up-right"
                          ></b-icon
                          >Abrir formulario de pregunta
                        </b-tooltip>

                        <b-modal
                          :id="`edit-cl-question-${question.id}`"
                          title="Editar Pregunta"
                          size="xl"
                          hide-footer
                          no-close-on-backdrop
                        >
                          <QuestionForm
                            :Question="question"
                            :question_test_id="question.question_test"
                            :evaluation_id="evaluation_id"
                            @updated="slotUpdatedQuestion(question)"
                          ></QuestionForm>
                        </b-modal>
                      </div>
                      <div v-else :key="question.id">N/A</div>
                    </template>
                  </b-popover>
                </div>
              </b-td>
              <b-td class="text-center align-middle">{{
                clQuestionScore(cognitive_level.id, 2, "Simple")
                  ? clQuestionScore(cognitive_level.id, 2, "Simple")
                  : "-"
              }}</b-td>
              <b-td class="text-center align-middle">
                <div
                  :id="`cl-questions-cell-4-${cognitive_level.id}`"
                  :class="{
                    'cl-questions-cell':
                      allows_crud &&
                      cognitiveLevelQuestions(cognitive_level.id, 2, 'Múltiple')
                        .length,
                    'cl-questions-about': cognitiveLevelQuestions(
                      cognitive_level.id,
                      2,
                      'Múltiple'
                    ).length,
                  }"
                >
                  {{
                    cognitiveLevelQuestions(
                      cognitive_level.id,
                      2,
                      "Múltiple"
                    ) &&
                    cognitiveLevelQuestions(cognitive_level.id, 2, "Múltiple")
                      .length > 0
                      ? cognitiveLevelQuestions(
                          cognitive_level.id,
                          2,
                          "Múltiple"
                        ).length
                      : "-"
                  }}
                  <b-popover
                    v-if="
                      cognitiveLevelQuestions(cognitive_level.id, 2, 'Múltiple')
                        .length
                    "
                    :target="`cl-questions-cell-4-${cognitive_level.id}`"
                    triggers="click"
                  >
                    <template #title>Preguntas</template>
                    <template
                      v-for="question in cognitiveLevelQuestions(
                        cognitive_level.id,
                        2,
                        'Múltiple'
                      )"
                    >
                      <div :key="question.id" v-if="!isNaN(question.id)">
                        <div
                          v-if="allows_crud"
                          :id="`question-link-${question.id}`"
                          class="popover-question"
                          @click="
                            openQuestionForm(question.id, cognitive_level.id)
                          "
                        >
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <div v-else>
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{ "Pregunta N° " + question.order }}
                        </div>
                        <b-tooltip
                          v-if="allows_crud"
                          :target="`question-link-${question.id}`"
                          variant="secondary"
                          placement="right"
                          delay="100"
                        >
                          <b-icon
                            class="mr-2"
                            icon="box-arrow-up-right"
                          ></b-icon
                          >Abrir formulario de pregunta
                        </b-tooltip>

                        <b-modal
                          :id="`edit-cl-question-${question.id}`"
                          title="Editar Pregunta"
                          size="xl"
                          hide-footer
                          no-close-on-backdrop
                        >
                          <QuestionForm
                            :Question="question"
                            :question_test_id="question.question_test"
                            :evaluation_id="evaluation_id"
                            @updated="slotUpdatedQuestion(question)"
                          ></QuestionForm>
                        </b-modal>
                      </div>
                      <div v-else :key="question.id">N/A</div>
                    </template>
                  </b-popover>
                </div>
              </b-td>
              <b-td class="text-center align-middle">{{
                clQuestionScore(cognitive_level.id, 2, "Múltiple")
                  ? clQuestionScore(cognitive_level.id, 2, "Múltiple")
                  : "-"
              }}</b-td>
            </b-tr>
          </template>
        </b-tbody>

        <b-tfoot class="custom-footer">
          <b-tr class="text-center align-middle">
            <b-td>Total </b-td>
            <b-td>{{
              evaluation_criterias()
                ? evaluation_criterias().filter((x) =>
                    verbInCognitiveLevelIds.includes(x.verb)
                  ).length
                : "-"
            }}</b-td>
            <b-td>
              {{
                clQuestionsAmount(1, "Breve") &&
                clQuestionsAmount(1, "Breve").length > 0
                  ? clQuestionsAmount(1, "Breve").length
                  : "-"
              }}</b-td
            >
            <b-td>{{
              clQuestionScoreAmount(1, "Breve")
                ? clQuestionScoreAmount(1, "Breve")
                : "-"
            }}</b-td>
            <b-td>{{
              clQuestionsAmount(1, "Extenso") &&
              clQuestionsAmount(1, "Extenso").length > 0
                ? clQuestionsAmount(1, "Extenso").length
                : "-"
            }}</b-td>
            <b-td>{{
              clQuestionScoreAmount(1, "Extenso")
                ? clQuestionScoreAmount(1, "Extenso")
                : "-"
            }}</b-td>
            <b-td>{{
              clQuestionsAmount(2, "Simple") &&
              clQuestionsAmount(2, "Simple").length > 0
                ? clQuestionsAmount(2, "Simple").length
                : "-"
            }}</b-td>
            <b-td>{{
              clQuestionScoreAmount(2, "Simple")
                ? clQuestionScoreAmount(2, "Simple")
                : "-"
            }}</b-td>
            <b-td>{{
              clQuestionsAmount(2, "Múltiple") &&
              clQuestionsAmount(2, "Múltiple").length > 0
                ? clQuestionsAmount(2, "Múltiple").length
                : "-"
            }}</b-td>
            <b-td>{{
              clQuestionScoreAmount(2, "Múltiple")
                ? clQuestionScoreAmount(2, "Múltiple")
                : "-"
            }}</b-td>
          </b-tr>
        </b-tfoot>
      </template>
      <template v-else>
        <b-td colspan="8" class="empty-instrument-alert">
          <b-icon icon="exclamation-circle"></b-icon> El instrumento actual no
          tiene preguntas creadas.
        </b-td>
      </template>
    </b-table-simple>
  </div>
</template>
<script>
export default {
  name: "CognitiveLevelST",
  components: {
    QuestionForm: () => import("../Test/QuestionForm.vue"),
  },
  props: {
    questions: { required: true },
    cognitive_levels: { required: true },
    evaluation_criterias: { required: true },
    evaluation_id: {
      required: true,
    },
    allows_crud: {},
    observation_table: { type: Boolean, default: false },
  },
  data() {
    return { show_popover: false };
  },
  computed: {
    verbInCognitiveLevelIds() {
      let list = [];
      this.cognitive_levels.forEach((cl) => {
        list = [...new Set(list.concat(cl.verbs.map((x) => x.id)))];
      });
      return list;
    },
  },
  methods: {
    getECPerCognitiveLevel(cl_id) {
      let cl_questions = this.questions.filter(
        (question) => question.cognitive_level == cl_id
      );
      let evaluation_criterias = 0;
      cl_questions.forEach((question) => {
        if (question.evaluation_criterias_micro != null)
          evaluation_criterias += question.evaluation_criterias_micro.length;
        if (question.evaluation_criterias_macro != null)
          evaluation_criterias += question.evaluation_criterias_macro.length;
      });
      return evaluation_criterias;
    },
    cognitiveLevelQuestions(cl_id, type, sub_type) {
      let cl_questions = [];
      if (type == 1) {
        if (sub_type == "Breve") {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.max_answer_length != null
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.max_answer_length == null
          );
        }
      } else {
        if (sub_type == "Simple") {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.how_many_selected == 1
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.how_many_selected > 1
          );
        }
      }
      return cl_questions;
    },
    clQuestionScore(cl_id, type, sub_type) {
      let cl_questions = [];
      if (type == 1) {
        if (sub_type == "Breve") {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.max_answer_length != null
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.max_answer_length == null
          );
        }
      } else {
        if (sub_type == "Simple") {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.how_many_selected == 1
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.cognitive_level == cl_id &&
              question.question_type == type &&
              question.how_many_selected > 1
          );
        }
      }
      let score_sum = 0;
      cl_questions.forEach((question) => {
        score_sum = score_sum + question.score;
      });
      return score_sum;
    },
    clQuestionsAmount(type, sub_type) {
      let cl_questions = [];
      if (type == 1) {
        if (sub_type == "Breve") {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type &&
              question.max_answer_length != null
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type &&
              question.max_answer_length == null
          );
        }
      } else {
        if (sub_type == "Simple") {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type && question.how_many_selected == 1
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type && question.how_many_selected > 1
          );
        }
      }
      return cl_questions;
    },
    clQuestionScoreAmount(type, sub_type) {
      let cl_questions = [];
      if (type == 1) {
        if (sub_type == "Breve") {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type &&
              question.max_answer_length != null
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type &&
              question.max_answer_length == null
          );
        }
      } else {
        if (sub_type == "Simple") {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type && question.how_many_selected == 1
          );
        } else {
          cl_questions = this.questions.filter(
            (question) =>
              question.question_type == type && question.how_many_selected > 1
          );
        }
      }
      let score_sum = 0;
      cl_questions.forEach((question) => {
        score_sum = score_sum + question.score;
      });
      return score_sum;
    },
    slotUpdatedQuestion(question) {
      this.$emit("updatedQuestion", question);
    },
    openQuestionForm(question_id) {
      this.show_popover = true;
      this.$bvModal.show(`edit-cl-question-${question_id}`);
      // const element = document.getElementById(`cl-questions-popover-${cl_id}`);
      // console.log(element);
    },
    closeAllPopovers() {
      this.$root.$emit("bv::hide::popover");
    },
  },
};
</script>
<style scoped>
.specification-table-content {
  overflow: auto;
}

table {
  width: 100%;
}
th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  font-size: var(--secondary-font-size);
  padding: 0 4px;
}
.th-color-variant {
  background-color: var(--primary-hover-color) !important;
}
.th-upper {
  background-color: var(--primary-color) !important;
}
td {
  padding: 2px 4px;
}

tfoot {
  background-color: #eee;
}
.cognitive-level {
  text-decoration: underline;
}
.empty-instrument-alert {
  text-align: center;
  padding: 2em 0;
}
.cl-questions-cell:hover {
  color: var(--kl-primary-button-color);
  text-decoration: underline;
  cursor: pointer;
}
.popover-question {
  cursor: pointer;
}
.popover-question:hover {
  color: var(--kl-primary-button-color);
}
.cl-questions-about {
  text-decoration: underline;
  cursor: help;
}
</style>